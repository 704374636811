import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios from "../../Axios";
import { message } from "antd";
import "./HeroSection.css";

const HeroSection = () => {
  const arrowRef = useRef();
  const [banners, setBanners] = useState(null);

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    speed: 500,
    nextArrow: false,
    prevArrow: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  async function getBanners() {
    try {
      const res = await axios.get("/api/banner/get-banners", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setBanners(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <>
      {/* Desktop and Tablet  */}
      <div className="container-fluid hero-container">
        <Slider ref={arrowRef} {...settings}>
          {banners &&
            banners?.map((item, index) => {
              return (
                <div className="p-3" key={index}>
                  <Link to={item?.link} target="_blank">
                    <img
                      src={`https://busanofficial.com/${item.image}`}
                      className="d-block w-100"
                      alt="banners"
                    />
                  </Link>
                </div>
              );
            })}
        </Slider>
        <div className="hero-slider-btns">
          <div onClick={() => arrowRef.current.slickPrev()}>
            <KeyboardArrowLeftIcon className="icon arrow-left" />
          </div>
          <div onClick={() => arrowRef.current.slickNext()}>
            <KeyboardArrowRightIcon className="icon arrow-right" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
