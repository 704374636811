import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import { message } from "antd";
import axios from "../Axios";
import "./AdminNotification.css";
import "./AdminPaymentGateway.css";

const AdminPaymentGateway = () => {
  const [gateway, setGateway] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();
    if (gateway === "") {
      return message.error("Please select a Payment Gateway");
    }
    if (gateway !== "onegateway" && gateway !== "pgateway") {
      return message.error("Invalid Payment Gateway");
    }
    try {
      const res = await axios.post(
        "/api/payment-gateway/update-gateway",
        {
          gateway,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message || "Payment Gateway Updated");
        getGateway();
      } else {
        message.error(res.data.message || "Failed to update Payment Gateway");
      }
    } catch (error) {
      console.log(error || "Failed to update Payment Gateway");
    }
  }

  async function getGateway() {
    try {
      const res = await axios.get(
        "/api/payment-gateway/get-gateway",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (res.data.success) {
        setGateway(res.data.data.gateway);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      message.error("Failed to get Payment Gateway");
      console.log(error);
    }
  }

  useEffect(() => {
    getGateway();
  }, []);

  const paymentGateway = [
    {
      name: "OneGateway",
      link: "onegateway",
      logo: (
        <img
          src="https://onegateway.in/assets/images/logo.svg"
          alt="onegateway"
        />
      ),
    },
    {
      name: "PGateway",
      link: "pgateway",
      logo: (
        <h1
          style={{
            fontWeight: "bold",
            color: "pink",
            fontSize: "20px",
            textShadow: "2px 2px 2px black",
            // padding: "10px 0",
          }}
        >
          P
        </h1>
      ),
    },
  ];

  return (
    <AdminLayout>
      <div className="admin-users">
        <div className="page-title">
          <h3 className="m-0">Payment Gateway</h3>
        </div>
        <hr />
        <div className="noti-container">
          <h5 className="text-dark mb-4">Select Payment Gateway</h5>
          <form onSubmit={handleSubmit}>
            <div className="form-group text-dark">
              {paymentGateway.map((item, index) => (
                <div
                  key={index}
                  className={`form-check ${
                    gateway === item.link ? "active" : ""
                  }`}
                  onClick={(e) => setGateway(item.link)}
                >
                  {item.logo}
                  <label htmlFor={item.link} className="form-check-label">
                    {item.name}
                  </label>
                </div>
              ))}
            </div>

            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};

export default AdminPaymentGateway;
