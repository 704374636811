import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import IMAGES from "../img/image";
import { useNavigate, useParams } from "react-router-dom";
import HowItWorks from "../components/Home/HowItWorks";
import axios from "../Axios";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import LockClockIcon from "@mui/icons-material/LockClock";
import VerifiedIcon from "@mui/icons-material/Verified";
import DiamondIcon from "@mui/icons-material/Diamond";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { setUser } from "../redux/features/userSlice";
import getUserData from "../utils/userDataService.js";
import CryptoJS from "crypto-js";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import "./ProductInfo.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { set } from "mongoose";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [userAllow, setUserAllow] = useState(false);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [mode, setMode] = useState("WALLET");
  //!NEW STATE
  const [playerId, setPlayerId] = useState("");
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [orderId, setOrderId] = useState(null);
  const [balance, setBalance] = useState("");
  const [popup, setPopup] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [displayPrice, setDisplayPrice] = useState(null);
  // Coupon Code
  const [couponCode, setCouponCode] = useState({
    code: "",
  });

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  //? ========================= SECRET KEY ==============
  const secretKey = "BUSAN@#$123OFFICIAL";
  const decryptPrice = (encryptedPrice) => {
    if (!secretKey) {
      console.error("Secret key is not defined");
      return encryptedPrice;
    }
    const bytes = CryptoJS.AES.decrypt(encryptedPrice, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };
  //? ========================= SECRET KEY ==============

  const generateOrderId = () => {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2); // Last two digits of the year
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    const randomNum = Math.floor(1000 + Math.random() * 9000); // Ensures a 4-digit number
    const orderId = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
    setOrderId(orderId);
  };

  useEffect(() => {
    generateOrderId();
  }, []);

  function setPriceAndId(amount) {
    if (user?.reseller === "yes") {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.resPrice;
      setSelectedPrice(price);
      setCouponCode({ code: "" });
      setDisplayPrice(null);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    } else {
      const price = product?.cost?.find(
        (item) => item.amount === amount
      )?.price;
      setSelectedPrice(price);
      setCouponCode({ code: "" });
      setDisplayPrice(null);
      const id = product?.cost?.find((item) => item.amount === amount)?.id;
      setProductId(id);
    }
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice =
          user && user?.reseller === "yes"
            ? res.data.data?.cost?.[0]?.resPrice
            : res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      // setLoading(true);
      const idPro = productId.split("&")[0];
      const object = {
        region:
          product?.apiName === "smileOne" ? product?.region : "philippines",
        userid: userId,
        zoneid: zoneId,
        productid: product?.apiName === "smileOne" ? idPro : "212",
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
        if (product?.user === res.data.use) {
          setUserAllow(true);
        } else {
          setUserAllow(false);
        }
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getProduct();
  }, [user]);

  function checkPlaceOrder(e) {
    if (product?.api === "no") {
      if (product?.fields === "2") {
        if (userId === "" || zoneId === "") {
          return message.error("Some Fields are missing");
        }
      } else if (product?.fields === "1") {
        if (userId === "") {
          return message.error("Some Fields are missing");
        }
      }
    } else if (product.api === "yes" && product?.apiName === "yokcash") {
      if (product?.gameName === "Clash Of Clans") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
      }
      if (product?.gameName === "Genshin Impact") {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
      if (
        product?.gameName === "Mobile Legends" ||
        product?.gameName === "ML Region Luar"
      ) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Enter Zone ID");
        }
      }
    } else if (product.api === "yes" && product?.apiName === "hopeStore") {
      if (product?.gameName === "Pubg") {
        if (userId === "") {
          return message.error("Enter ID");
        }
      }
      if (
        product?.gameName === "Genshin Impact" ||
        product?.gameName === "Mobile Legends"
      ) {
        if (userId === "") {
          return message.error("Enter User ID");
        }
        if (zoneId === "") {
          return message.error("Select Server");
        }
      }
    } else {
      if (userId === "") {
        return message.error("Enter User ID");
      }
      if (zoneId === "") {
        return message.error("Enter Zone ID");
      }
      if (playerCheck === null) {
        return message.error("Check Your Player Name");
      }
    }

    if (product?.api === "yes") {
      if (product?.apiName === "yokcash") {
        if (mode === "UPI") {
          handleYokcashUpiOrder(e);
        } else {
          handleYokcashWalletOrder(e);
        }
      } else if (product?.apiName === "hopeStore") {
        if (mode === "UPI") {
          handleHopeStoreUpiOrder(e);
        } else {
          handleHopeStoreWalletOrder(e);
        }
      } else {
        if (mode === "UPI") {
          handleSmileOneUpiOrder(e);
        } else {
          handleSmileOneWalletOrder(e);
        }
      }
    } else {
      if (mode === "UPI") {
        handleUpiOrder(e);
      } else {
        handleWalletOrder(e);
      }
    }
  }

  // YOKCASH
  async function handleYokcashUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: selectedPrice,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: `https://busanofficial.com/api/yokcash/status?orderId=${orderId}&couponCode=${couponCode._id ? couponCode._id : ''}`,
        paymentNote:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          amount +
          "@" +
          product?.name,
        couponCode: couponCode._id,
      };

      const response = await axios.post("/api/yokcash/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl; // OneGateway
        setIsSuccess(true);
      } else if (response.data.success && response.data.data.payment_url) {
        window.location.href = response.data.data.payment_url; // PGateway
        setIsSuccess(true);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setIsSuccess(false);
        setPopup(true);
      }
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
      setPopup(true);
    }
  }
  async function handleYokcashWalletOrder(e) {
    if (parseInt(balance) < parseInt(displayPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
        couponCode: couponCode._id,
      };

      setLoading(true);
      const res = await axios.post("/api/yokcash/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.removeItem("cart");
        setLoading(false);
        setIsSuccess(true);
      } else {
        setLoading(false);
        message.error(res.data.message);
        setIsSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
    } finally {
      setPopup(true);
    }
  }

  // HOPESTORE
  async function handleHopeStoreUpiOrder(e) {
    e.preventDefault();
    try {
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: selectedPrice,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: `https://busanofficial.com/api/hopestore/status?orderId=${orderId}&couponCode=${couponCode._id ? couponCode._id : ''}`,
        paymentNote:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          amount +
          "@" +
          product?.name,
        couponCode: couponCode._id,
      };

      const response = await axios.post(
        "/api/hopestore/create",
        paymentObject,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );

      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl; // OneGateway
        setIsSuccess(true);
      } else if (response.data.success && response.data.data.payment_url) {
        window.location.href = response.data.data.payment_url; // PGateway
        setIsSuccess(true);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setIsSuccess(false);
        setPopup(true);
      }
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
      setPopup(true);
    }
  }
  async function handleHopeStoreWalletOrder(e) {
    if (parseInt(balance) < parseInt(displayPrice)) {
      return message.error("Balance is less for this order");
    }
    try {
      const paymentObject = {
        order_id: orderId,
        txn_amount: selectedPrice,
        product_name: amount,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        txn_note:
          userId.trim() +
          "@" +
          zoneId.trim() +
          "@" +
          productId +
          "@" +
          product?.name +
          "@" +
          amount,
        couponCode: couponCode._id,
      };

      setLoading(true);
      const res = await axios.post("/api/hopestore/wallet", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        localStorage.removeItem("cart");
        setLoading(false);
        setIsSuccess(true);
      } else {
        setLoading(false);
        message.error(res.data.message);
        setIsSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
    } finally {
      setPopup(true);
    }
  }

  // SMILE
  const handleSmileOneUpiOrder = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        message.error("Unauthorized: Please log in again.");
        return setLoading(false);
      }

      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: selectedPrice,
        customerName: user?.fname,
        customerEmail: user?.email,
        customerNumber: user?.mobile,
        redirectUrl: `https://busanofficial.com/api/smile/status?orderId=${orderId}&couponCode=${couponCode._id ? couponCode._id : ''}`,
        paymentNote: `${userId.trim()}@${zoneId.trim()}@${productId}@${amount}@${
          product?.region
        }@${product?.name}`,
        couponCode: couponCode._id,
      };

      const response = await axios.post("/api/smile/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      // redirecting user

      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl; // OneGateway
        setIsSuccess(true);
      } else if (response.data.success && response.data.data.payment_url) {
        window.location.href = response.data.data.payment_url; // PGateway
        setIsSuccess(true);
      } else {
        message.error(response.data.message || "Failed to create UPI order.");
        setLoading(false);
        setIsSuccess(false);
        setPopup(true);
      }
    } catch (error) {
      console.log(error);
      message.error("An error occurred while creating the order.");
      setIsSuccess(false);
      setPopup(true);
    }
  };
  const handleSmileOneWalletOrder = async (e) => {
    e.preventDefault();
    if (parseInt(balance) < parseInt(displayPrice)) {
      return message.error("Insufficient balance for this order.");
    }
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      if (!token) {
        message.error("Unauthorized: Please log in again.");
        return setLoading(false);
      }

      const orderObject = {
        client_txn_id: orderId,
        userid: userId.trim(),
        zoneid: zoneId.trim(),
        productid: productId,
        region: product.region,
        customer_email: user?.email,
        customer_mobile: user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
        couponCode: couponCode._id,
      };

      const res = await axios.post("/api/smile/wallet", orderObject, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data.success) {
        message.success(
          res.data.message || "Wallet order created successfully."
        );
        setLoading(false);
        setIsSuccess(true);
      } else {
        setLoading(false);
        message.error(res.data.message);
        setIsSuccess(false);
      }
      setPopup(true);
    } catch (error) {
      console.log(error);
      message.error("An error occurred while processing the wallet order.");
      setIsSuccess(false);
      setPopup(true);
    }
  };

  // MANUAL
  const handleUpiOrder = async (e) => {
    e.preventDefault();
    try {
      const paymentObject = {
        scannerIncluded: true,
        orderId: orderId,
        amount: selectedPrice,
        customerName: user?.fname,
        customerNumber: user?.mobile,
        customerEmail: user?.email,
        redirectUrl: `https://busanofficial.com/api/manual/status?orderId=${orderId}&couponCode=${couponCode._id ? couponCode._id : ''}`,
        paymentNote:
          userId.trim() + "@" + zoneId + "@" + amount + "@" + product?.name,
        couponCode: couponCode._id,
      };

      const response = await axios.post("/api/manual/create", paymentObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.data.success && response.data.data.paymentUrl) {
        window.location.href = response.data.data.paymentUrl; // OneGateway
        setIsSuccess(true);
      } else if (response.data.success && response.data.data.payment_url) {
        window.location.href = response.data.data.payment_url; // PGateway
        setIsSuccess(true);
      } else {
        message.error(response.data.message);
        setLoading(false);
        setIsSuccess(false);
        setPopup(true);
      }
    } catch (error) {
      console.log(error);
      setIsSuccess(false);
      setPopup(true);
    }
  };
  const handleWalletOrder = async (e) => {
    if (parseInt(balance) < parseInt(displayPrice)) {
      return message.error("Balance is less for this order");
    }
    e.preventDefault();
    try {
      const orderObject = {
        api: "no",
        orderId: orderId,
        userid: userId.trim(),
        zoneid: zoneId.trim(),
        customer_email: user && user?.email,
        customer_mobile: user && user?.mobile,
        pname: product?.name,
        amount: amount,
        price: selectedPrice,
        couponCode: couponCode._id,
      };
      setLoading(true);
      const res = await axios.post("/api/payment/wallet", orderObject, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setLoading(false);
        message.success(res.data.message);
        navigate("/orders");
        localStorage.removeItem("cart");
        localStorage.setItem("orderProcess", "no");
        setIsSuccess(true);
      } else {
        message.error(res.data.message);
        setLoading(false);
        localStorage.setItem("orderProcess", "no");
        setIsSuccess(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      localStorage.setItem("orderProcess", "no");
      setIsSuccess(false);
    } finally {
      setPopup(true);
    }
  };

  // COUPON CODE
  const handleCouponCode = async (e) => {
    e.preventDefault();
    try {
      if (couponCode.code === "") {
        return message.error("Enter coupon code");
      }
      if (couponCode._id) {
        return message.error("Coupon code already applied");
      }
      const res = await axios.post(
        "/api/coupon/apply",
        {
          code: couponCode.code,
          productPrice: selectedPrice,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setCouponCode(res.data.data);
        setDisplayPrice(selectedPrice - res.data.data.discount);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      message.error(
        error.response.data.message || "Error applying coupon code"
      );
    }
  };

  return (
    <Layout>
      <div
        className="p-info-container area"
        style={{
          backgroundImage: `url(/${product?.bannerImage?.replace(/\\/g, "/")})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="pro-img">
          <img src={`/${product?.image}`} alt="" />
          <div>
            <h2 className="m-0">{product?.name}</h2>
            <h6 className="m-0 d-none d-md-none d-lg-block">
              {product?.descTwo}
            </h6>
            <div className="features">
              <button>
                <SupportAgentIcon className="icon" />
                24/7 Support
              </button>
              <button>
                <AssuredWorkloadIcon className="icon" />
                Secure Payment
              </button>
              <button>
                <VerifiedUserIcon className="icon" />
                Official Store
              </button>
              <button>
                <WorkspacePremiumIcon className="icon" />
                Quality Assurance
              </button>
              <button>
                <ElectricBoltIcon className="icon" />
                Fast Delivery
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="product-info-container">
        {loading && (
          <div className="order-process">
            <DiamondIcon className="icon" />
            <div class="wrapper">
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="circle"></div>
              <div class="shadow"></div>
              <div class="shadow"></div>
              <div class="shadow"></div>
            </div>
          </div>
        )}

        {/* ========================================= LEFT SECTION*/}
        {/* ========================================= LEFT SECTION*/}
        {/* ========================================= LEFT SECTION*/}
        <div className="product-info-img d-none d-lg-block">
          {/* <img src={`/${product?.image}`} alt="" /> */}
          <h3>{product?.name}</h3>
          <div className="info-btns">
            <button>
              <LockClockIcon className="icon me-2" />
              Secure Payments
            </button>
            <button>
              <VerifiedIcon className="icon me-2" />
              Official Distributor
            </button>
          </div>
          <span>{product?.desc}</span>
        </div>
        {/* ========================================= RIGHT SECTION*/}
        {/* ========================================= RIGHT SECTION*/}
        {/* ========================================= RIGHT SECTION*/}
        <div className="product-info-content mt-5 mt-lg-0 mb-2">
          {/* USER INPUTS  */}
          <div className="white-bg">
            <h4 className="text-dark m-0 hrline mb-4">Enter IDs</h4>
            {product?.apiName === "smileOne" ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="User ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <input
                  className="player-tag"
                  type="text"
                  name="zoneid"
                  placeholder="(    ZONE ID    )"
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
                <button className="p-check-btn" onClick={handleCheckPlayer}>
                  Check Player Name
                  {loading && (
                    <div class="spinner-grow spinner-grow-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                {userAllow && playerCheck && (
                  <span className="text-success">Username: {playerCheck}</span>
                )}
                {!userAllow && playerCheck && (
                  <span className="text-danger">Username: {playerCheck}</span>
                )}
              </>
            ) : (product?.apiName === "yokcash" ||
                product?.apiName === "hopeStore") &&
              (product?.gameName === "ML Region Luar" ||
                product?.gameName === "Mobile Legends") ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="User ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <input
                  className="player-tag"
                  type="text"
                  name="zoneid"
                  placeholder="(    ZONE ID    )"
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
                <button
                  className="p-check-btn"
                  style={
                    product?.apiName === "yokcash" &&
                    product?.name === "MLBB INDONESIA"
                      ? { display: "none" }
                      : {}
                  }
                  onClick={handleCheckPlayer}
                >
                  Check
                  {loading && (
                    <div class="spinner-grow spinner-grow-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
                <span className="text-success">
                  {playerCheck && "Username: " + playerCheck}
                </span>
              </>
            ) : (product?.apiName === "yokcash" ||
                product?.apiName === "hopeStore") &&
              (product?.gameName === "Genshin Impact" ||
                product?.gameName === "Honkai Star Rail") ? (
              <>
                <div className="d-flex align-items-center">
                  <input
                    className="player-tag"
                    type="text"
                    name="userId"
                    placeholder="USER ID"
                    onChange={(e) => setUserId(e.target.value)}
                    value={userId}
                  />
                </div>
                <select
                  name="zoneid"
                  className="form-select player-select"
                  onChange={(e) => setZoneId(e.target.value)}
                >
                  <option value="">Select Server</option>
                  <option value="America">America</option>
                  <option value="Asia">Asia</option>
                  <option value="Europe">Europe</option>
                  <option value="TW_HK_MO">TW_HK_MO</option>
                </select>
              </>
            ) : (product?.apiName === "yokcash" ||
                product?.apiName === "hopeStore") &&
              (product?.gameName === "Free Fire" ||
                product?.gameName === "Call Of Duty Mobile" ||
                product?.gameName === "PUBG Mobile" ||
                product?.gameName === "Valorant" ||
                product?.gameName === "Clash Of Clans" ||
                product?.gameName === "League of Legends Wild Rift") ? (
              <input
                className="player-tag"
                type="text"
                name="userId"
                placeholder={`${product?.tag}`}
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
              />
            ) : product?.fields === "2" ? (
              <>
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder={`${product?.tagOne}`}
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
                <input
                  className="player-tag"
                  type="text"
                  name="zoneId"
                  placeholder={`${product?.tagTwo}`}
                  onChange={(e) => setZoneId(e.target.value)}
                  value={zoneId}
                />
              </>
            ) : (
              <input
                className="player-tag"
                type="text"
                name="userId"
                placeholder={`${product?.tagOne}`}
                onChange={(e) => setUserId(e.target.value)}
                value={userId}
              />
            )}
          </div>

          {/* ========================================= PRODUCT AMOUNT */}
          {/* ========================================= PRODUCT AMOUNT */}
          {/* ========================================= PRODUCT AMOUNT */}

          <div className="white-bg">
            <h4 className="text-dark m-0 hrline mb-4">SELECT PACK</h4>
            <div className="p-amount">
              {product?.cost?.map((item, index) => {
                return (
                  <div
                    onClick={() => {
                      setAmount(item.amount);
                      setPriceAndId(item.amount);
                    }}
                    key={index}
                    className={`amount ${amount === item?.amount && "active"}`}
                  >
                    <span>
                      <div
                        className={`amt-img ${
                          amount === item?.amount && "active"
                        }`}
                      >
                        <div>{item.amount}</div>
                        <img src={item?.pimg} alt="" />
                      </div>
                      <div className="price">
                        ₹
                        {user?.reseller === "yes"
                          ? item?.resPrice
                          : item?.price}
                      </div>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          {/* ========================================= PAYMENT */}
          {/* ========================================= PAYMENT */}
          {/* ========================================= PAYMENT */}

          <div className="white-bg">
            <h4 className="text-dark m-0 hrline mb-4">SELECT PAYMENT</h4>
            <div className="payment">
              <div
                onClick={() => setMode("WALLET")}
                className={`wallet ${mode === "WALLET" && "active"}`}
              >
                <div>
                  <AccountBalanceWalletIcon className="icon" />
                  <span className="ms-2 fs-4">
                    {user && <b>Rs. {balance}</b>}
                  </span>
                </div>
                <h4 className="m-0">Wallet</h4>
              </div>
              <div
                onClick={() => setMode("UPI")}
                className={`upi ${mode === "UPI" && "active"}`}
              >
                <img src={IMAGES.upi} alt="" />
                <h4 className="m-0">UPI</h4>
              </div>
            </div>
          </div>
          {/* ========================================= Coupon Code */}
          {/* ========================================= Coupon Code */}

          <div className="white-bg">
            <h4 className="text-dark m-0 hrline mb-4">APPLY COUPON CODE</h4>
            <div className="coupon d-flex flex-column gap-2 align-items-center">
              <input
                type="text"
                className="player-tag"
                placeholder="Enter Coupon Code"
                value={couponCode.code}
                onChange={(e) =>
                  setCouponCode({ code: e.target.value.toLocaleUpperCase() })
                }
              />
              <button className="p-check-btn" onClick={handleCouponCode}>
                Apply
              </button>
            </div>
          </div>

          {/* ========================================= BUY NOW */}
          {/* ========================================= BUY NOW */}

          <div className="white-bg">
            <div className="d-flex justify-content-between align-items-center position-relative">
              <h4 className="text-dark m-0 hrline mb-4">BUY!</h4>
              <h4 className="text-dark my-0">
                {displayPrice === null ? (
                  <span>Rs. {selectedPrice} </span>
                ) : (
                  <div className="d-flex justify-content-between gap-3">
                    <span className="line-through">Rs. {selectedPrice} </span>
                    <span>Rs. {displayPrice}</span>
                  </div>
                )}
              </h4>
            </div>
            {couponCode._id && (
              <span
                className="d-block m-0 text-end"
                style={{ color: "green", fontWeight: "600" }}
              >
                <small>discount - Rs. {couponCode.discount}</small>
              </span>
            )}
            <span className="d-block m-0 text-end text-dark">
              <small>
                {amount} {" | "}
                <span className="text-danger">Payment Mode - {mode}</span>
              </small>
            </span>
            {!user ? (
              <button
                className="p-check-btn"
                onClick={() => navigate("/login")}
              >
                Please Login first
              </button>
            ) : user?.block === "yes" || product?.stock === "No" ? (
              <button className="p-check-btn" style={{ opacity: "0.7" }}>
                Out of Stock
              </button>
            ) : product?.apiName === "smileOne" && !userAllow ? (
              <button className="p-check-btn disable">
                This ID is not Allowed
              </button>
            ) : (
              <button onClick={checkPlaceOrder} className="p-check-btn">
                BUY NOW
              </button>
            )}
          </div>
        </div>
      </div>
      <HowItWorks />
      {popup && (
        <div className="popup-bg">
          <div className="popup">
            <div className="popup-content">
              <div className="popup-header">
                <h3>{isSuccess ? "Payment Successful" : "Payment Failed"}</h3>
                <CancelIcon className="icon" onClick={() => setPopup(false)} />
              </div>
              <div className="popup-body">
                {isSuccess ? (
                  <>
                    <CheckCircleIcon
                      style={{ color: "green" }}
                      className="icon"
                    />
                    <h4>Payment Successful</h4>
                    <div className="popup-data">
                      <p>
                        <strong>User ID:</strong> <span>{userId}</span>
                      </p>
                      <p>
                        <strong>Zone ID:</strong> <span>{zoneId}</span>
                      </p>
                      <p>
                        <strong>Payment Mode:</strong> <span>{mode}</span>
                      </p>
                      <p>
                        <strong>Status:</strong>{" "}
                        <span
                          style={
                            isSuccess ? { color: "green" } : { color: "red" }
                          }
                        >
                          {isSuccess ? "Success" : "Failed"}
                        </span>
                      </p>
                      <p>
                        <strong>Order No.:</strong> <span>{orderId}</span>
                      </p>
                      <p>
                        <strong>Payment Time:</strong>{" "}
                        <span>{new Date().toLocaleString()}</span>
                      </p>
                      <p>
                        <strong>Price:</strong> <span>₹{selectedPrice}</span>
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <InfoIcon style={{ color: "red" }} className="icon" />
                    <h4>Payment Failed</h4>
                    <p>
                      Your payment has been failed. Please try again or contact
                      us on WhatsApp number +91 7005581860
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ProductInfo;
