import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminUsers.css";
import { useNavigate } from "react-router-dom";
import axios from "../Axios";
import "./AdminAddProduct.css";
import { message } from "antd";

const AdminAddProduct = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [images, setImages] = useState(null);
  const [yokcash, setYokcash] = useState(null);
  const [hopeStore, setHopeStore] = useState(null);

  const [form, setForm] = useState({
    name: "",
    desc: "",
    category: "",
    hotTags: "",
    api: "",
    apiName: "",
    gameName: "",
    region: "",
    stock: "Yes",
    images: [],
    fields: "",
    user: "",
    tagOne: "",
    tagTwo: "",
    bannerImage: null, // Added
  });
  const [cost, setCost] = useState([
    { id: "", amount: "", price: "", pimg: "", resPrice: "" },
  ]);

  const handleAddCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index + 1, 0, {
      id: "",
      amount: "",
      price: "",
      pimg: "",
      resPrice: "",
    });
    setCost(updatedCost);
  };
  const handleRemoveCostField = (index) => {
    const updatedCost = [...cost];
    updatedCost.splice(index, 1);
    setCost(updatedCost);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "image") {
      setForm({ ...form, [name]: e.target.files });
    } else if (name === "bannerImage" || name === "instructionImage") {
      setForm({ ...form, [name]: e.target.files[0] });
    } else if (
      name.startsWith("id") ||
      name.startsWith("amount") ||
      name.startsWith("price") ||
      name.startsWith("pimg") ||
      name.startsWith("resPrice")
    ) {
      const index = parseInt(name.split("-")[1]);
      const updatedCost = [...cost];
      const property = name.startsWith("amount")
        ? "amount"
        : name.startsWith("price")
        ? "price"
        : name.startsWith("pimg")
        ? "pimg"
        : name.startsWith("resPrice")
        ? "resPrice"
        : "id";
      updatedCost[index][property] = value;
      setCost(updatedCost);
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleAddProduct = async () => {
    const formData = new FormData();
    formData.append("name", form?.name);
    formData.append("api", form?.api);
    formData.append("apiName", form?.apiName);
    formData.append("gameName", form?.gameName);
    formData.append("hotTags", form?.hotTags);
    formData.append("region", form?.region);
    formData.append("stock", form?.stock);
    formData.append("desc", form?.desc);
    formData.append("category", form?.category);
    formData.append("user", form?.user);
    formData.append("fields", form?.fields);
    formData.append("tagOne", form?.tagOne);
    formData.append("tagTwo", form?.tagTwo);
    formData.append("cost", JSON.stringify(cost)); // Stringify the cost array
    formData.append("image", selectedFile);

    if (form.bannerImage) {
      formData.append("bannerImage", form.bannerImage);
    }
    if (form.instructionImage) {
      formData.append("instructionImage", form.instructionImage);
    }

    setLoading(true);

    try {
      const res = await axios.post("/api/product/add-product", formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      });

      if (res.data.success) {
        message.success(res.data.message);
        setLoading(false);
        setForm({
          name: "",
          api: "",
          desc: "",
          tag: "",
          stock: "Yes",
          images: [],
          bannerImage: null, // Reset bannerImage
        });
        setCost([{ amount: "", price: "", pimg: "", resPrice: "" }]);
        // Reset the value of the file input using the ref
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
    }
  };

  const getMobileLegendGame = async () => {
    try {
      const res = await axios.post(
        "/api/product/get-mobile-legend",
        { region: form?.region },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (res.data.success) {
        setData(res.data.data.product);
      } else {
        message.error("Api Error, Try after sometime");
      }
    } catch (error) {
      console.log(error);
    }
  };

  async function getAllImages() {
    try {
      const res = await axios.get("/api/image/get-images");
      if (res.data.success) {
        setImages(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getAllImages();
  }, []);

  useEffect(() => {
    if (form?.region !== "") {
      getMobileLegendGame();
    }
  }, [form?.region]);

  const fetchYokcashServices = async () => {
    try {
      const res = await axios.post("/api/yokcash/get-yokcash", {
        gameName: form?.gameName,
      });
      if (res.data.success) {
        setYokcash(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const fetchHopeStoreServices = async () => {
    try {
      const res = await axios.post("/api/hopestore/get-hopestore", {
        gameName: form?.gameName,
      });
      if (res.data.success) {
        setHopeStore(res.data.data);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    if (form?.apiName === "yokcash" && form?.gameName !== "") {
      fetchYokcashServices();
    } else if (form?.apiName === "hopeStore" && form?.gameName !== "") {
      fetchHopeStoreServices();
    }
  }, [form?.gameName]);

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Add Product</h3>
        </div>
        <hr />
        <div className="add-product-container">
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="image"
              required
              multiple
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </div>
          <span className="text-dark">
            <small>Banner Image</small>
          </span>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              aria-label="Select Banner Image"
              type="file"
              accept=".jpg, .jpeg, .png, .webp"
              name="bannerImage"
              required
              ref={fileInputRef}
              onChange={handleChange}
            />
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="name"
              onChange={handleChange}
              value={form?.name}
              type="text"
              placeholder="Enter name"
            />
          </div>
          <div className="form-fields mb-3">
            <textarea
              style={{ border: "1px solid #000" }}
              name="desc"
              id=""
              cols="30"
              rows="3"
              placeholder="Description"
              className="form-control"
              onChange={handleChange}
              value={form?.desc}
            ></textarea>
          </div>
          <div className="form-fields mb-3">
            <input
              className="w-100"
              name="hotTags"
              onChange={handleChange}
              value={form?.hotTags}
              type="text"
              placeholder="Enter Hot Tags"
            />
          </div>
          <div className="form-fields mb-3">
            <select
              onChange={handleChange}
              value={form?.category}
              name="category"
              className="w-100"
            >
              <option value="">Category</option>
              <option value="Popular">Popular</option>
              <option value="Direct Topup">Direct Topup</option>
              <option value="Coupon Code">Coupon Code</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-fields mb-3">
            <select
              onChange={handleChange}
              value={form?.api}
              name="api"
              className="w-100"
            >
              <option value="">API BASED?</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          {form?.api === "yes" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.apiName}
                name="apiName"
                className="w-100"
              >
                <option value="">Select API</option>
                <option value="smileOne">Smile One Api</option>
                <option value="yokcash">Yokcash Api</option>
                <option value="hopeStore">Hope Store Api</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "hopeStore" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.gameName}
                name="gameName"
                className="w-100"
              >
                <option value="">Select Game</option>
                <option value="Mobile Legends">Mobile Legends</option>
                <option value="Genshin Impact">Genshin Impact</option>
                <option value="PUBG Mobile">PUBG Mobile</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "yokcash" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.gameName}
                name="gameName"
                className="w-100"
              >
                <option value="">Select Game</option>
                <option value="Mobile Legends">Mobile Legends</option>
                <option value="ML Region Luar">ML Region Luar</option>
                <option value="Clash Of Clans">Clash Of Clans</option>
                <option value="Genshin Impact">Genshin Impact</option>
                <option value="Free Fire">Free Fire</option>
              </select>
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "smileOne" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.region}
                name="region"
                className="w-100"
              >
                <option value="">Select Region</option>
                <option value="brazil">Brazil</option>
                <option value="philippines">Philippines</option>
              </select>
            </div>
          )}
          {form?.api === "no" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.fields}
                name="fields"
                className="w-100"
              >
                <option value="">Fields</option>
                <option value="1">1 (USER ID/PlayerId/Link)</option>
                <option value="2">2 (USERID ZONEID)</option>
              </select>
            </div>
          )}
          {(form?.fields === "1" || form?.fields === "2") && (
            <div className="form-fields mb-3">
              <input
                className="w-100"
                name="tagOne"
                onChange={handleChange}
                value={form?.tagOne}
                type="text"
                placeholder="Enter Tag"
              />
            </div>
          )}
          {form?.fields === "2" && (
            <div className="form-fields mb-3">
              <input
                className="w-100"
                name="tagTwo"
                onChange={handleChange}
                value={form?.tagTwo}
                type="text"
                placeholder="Enter tag"
              />
            </div>
          )}
          {form?.api === "yes" && form?.apiName === "smileOne" && (
            <div className="form-fields mb-3">
              <select
                onChange={handleChange}
                value={form?.user}
                name="user"
                className="w-100"
              >
                <option value="">Allow User</option>
                <option value="a">a</option>
                <option value="b">b</option>
                <option value="c">c</option>
              </select>
            </div>
          )}
          <div className="form-fields mb-3">
            <label htmlFor="" className="text-dark">
              <small>Stock</small>
            </label>
            <select
              onChange={handleChange}
              value={form?.stock}
              name="stock"
              className="w-100"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          {cost &&
            cost?.map((item, index) => (
              <div className="d-flex form-fields mb-3" key={index}>
                <input
                  className="w-100"
                  name={`id-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.id || ""}
                  type="text"
                  placeholder="Enter id"
                />
                <input
                  className="w-100"
                  name={`amount-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.amount || ""}
                  type="text"
                  placeholder="Enter Amount"
                />
                <input
                  className="w-100"
                  name={`price-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.price || ""}
                  type="text"
                  placeholder="Enter Price"
                />{" "}
                <input
                  className="w-100"
                  name={`resPrice-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.resPrice || ""}
                  type="text"
                  placeholder="Enter Reseller Price"
                />
                <input
                  className="w-100"
                  name={`pimg-${index}`}
                  onChange={handleChange}
                  value={cost[index]?.pimg || ""}
                  type="text"
                  placeholder="Enter Image"
                />
                <button onClick={() => handleAddCostField(index)}>+</button>
                {index > 0 && (
                  <button onClick={() => handleRemoveCostField(index)}>
                    -
                  </button>
                )}
              </div>
            ))}
          <button className="w-100 py-3" onClick={handleAddProduct}>
            Add
          </button>
        </div>
      </div>

      {/* API PRO LIST  */}
      {form?.apiName === "smileOne" && data && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>ID</th>
              <th>SPU</th>
              <th>PRICE</th>
              <th>COST PRICE</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, index) => {
              return (
                <tr>
                  <td>{item?.id}</td>
                  <td>{item.spu}</td>
                  <td>{item.price}</td>
                  <td>{item.cost_price}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {form?.apiName === "yokcash" && yokcash && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>Product Id</th>
              <th>Name</th>
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {yokcash?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td>{item?.nama_layanan}</td>
                  <td>{item?.kategori}</td>
                  <td>{item?.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}

      {form?.apiName === "hopeStore" && hopeStore && (
        <table className="table mt-5 bg-white text-dark">
          <thead>
            <tr>
              <th>Product Id</th>
              <th>Name</th>
              <th>Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {hopeStore?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item?.id}</td>
                  <td>{item?.nama_layanan}</td>
                  <td>{item?.kategori}</td>
                  <td>{item?.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </AdminLayout>
  );
};

export default AdminAddProduct;
