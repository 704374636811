import React, { useState, useEffect } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminAddCoupon.css";
import axios from "../Axios";
import { message, Modal } from "antd"; // Import axios for making API calls
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const AdminAddCoupon = () => {
  const [loading, setLoading] = useState(false);
  const [tabs, setTabs] = useState(0);
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  // coupons
  const [newCouponCode, setNewCouponCode] = useState({
    code: "",
    discount: "",
    minPrice: "",
    expirationDate: "",
  });

  const handleAddCoupon = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await axios.post(
        "/api/coupon/create",
        {
          code: newCouponCode.code,
          discount: newCouponCode.discount,
          minPrice: newCouponCode.minPrice,
          expirationDate: newCouponCode.expirationDate,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      if (res.data.success) {
        message.success(res.data.message);
        setNewCouponCode({
          code: "",
          discount: "",
          minPrice: "",
          expirationDate: "",
        });
        getAllCoupon();
        message.success(res.data.message);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      message.error("Error adding coupon code");
    }
  };

  const getAllCoupon = async () => {
    try {
      const res = await axios.get("/api/coupon", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setData(res.data.data);
      }
    } catch (error) {
      console.log(error);
      message.error("Error getting coupon");
    }
  };

  useEffect(() => {
    getAllCoupon();
  }, []);

  const handleDeleteCoupon = async (id) => {
    try {
      const res = await axios.delete(`/api/coupon/${id}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        message.success(res.data.message);
        getAllCoupon();
      }
    } catch (error) {
      console.log(error);
      message.error("Error deleting coupon code");
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "code") {
      e.target.value = e.target.value.toUpperCase();
    }
    setNewCouponCode({ ...newCouponCode, [e.target.name]: e.target.value });
  };

  // Handle Modal
  const handleOk = () => {
    handleEditCoupon(selectedCoupon?._id);
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const editCoupon = (coupon) => {
    setShowModal(true);
    setSelectedCoupon(coupon);
  };

  const handleEditChange = (e) => {
    if (e.target.name === "code") {
      e.target.value = e.target.value.toUpperCase();
    }
    setSelectedCoupon({ ...selectedCoupon, [e.target.name]: e.target.value });
  };

  const handleEditCoupon = async (id) => {
    try {
      const res = await axios.put(`/api/coupon/${id}`, selectedCoupon, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setShowModal(false);
        message.success(res.data.message);
        getAllCoupon();
      }
    } catch (error) {
      console.log(error);
      message.error("Error updating coupon code");
    }
  };

  return (
    <AdminLayout>
      <div className="admin-users-container">
        <div className="page-title">
          <h3 className="m-0">Coupon Code</h3>
        </div>
        <hr />
        <div className="admin-coupon-container">
          <h5>Add Coupon</h5>
          <hr className="m-0 mb-3" />
          <div className="add-coupon-container form-fields">
            <input
              type="text"
              className="form-control"
              placeholder="Enter coupon code"
              name="code"
              value={newCouponCode.code}
              onChange={handleChange}
            />
            <input
              type="text"
              className="form-control"
              placeholder="Enter Discount"
              name="discount"
              value={newCouponCode.discount}
              onChange={handleChange}
            />
            <input
              type="text"
              className="form-control"
              placeholder="Minimum Purchase Price"
              name="minPrice"
              value={newCouponCode.minPrice}
              onChange={handleChange}
            />
            <input
              type="date"
              className="form-control"
              placeholder="Expiration Date"
              name="expirationDate"
              value={newCouponCode.expirationDate}
              onChange={handleChange}
            />
            <button
              className="add-to-cart-btn m-0"
              onClick={handleAddCoupon}
            >
              {loading ? "Adding.." : "Add"}
            </button>
          </div>
          <hr className="m-0 mt-3" />
          <div className="coupons-container">
            <h5>Coupon List</h5>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Coupon Name</th>
                  <th>Discount</th>
                  <th>Min Price</th>
                  <th>Expiration Date</th>
                  <th>State</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {data?.length === 0 ? (
                  <tr className="text-center">
                    <td colSpan="6">No Coupon Found</td>
                  </tr>
                ) : (
                  data &&
                  data?.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item?.code}</td>
                        <td>{item?.discount}</td>
                        <td>{item?.minPrice}</td>
                        <td>
                          {new Date(item?.expirationDate).toLocaleDateString(
                            "en-IN"
                          )}
                        </td>
                        <td>{item?.isActive ? (
                          <span className="active">Active</span>
                        ) : 
                          <span className="inactive">Inactive</span>
                        }</td>
                        <td>
                          <DeleteIcon
                            className="icon mx-1"
                            style={{ color: "red" }}
                            onClick={() => handleDeleteCoupon(item?._id)}
                          />
                          <EditIcon
                            className="icon mx-1"
                            style={{ color: "blue" }}
                            onClick={() => editCoupon(item)}
                          />
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          title="Add Coupon"
          visible={showModal}
          onOk={handleOk}
          onCancel={handleCancel}
          className="coupon-card"
        >
          <div className="add-coupon-container form-fields">
            <input
              type="text"
              className="form-control"
              placeholder="Enter coupon code"
              name="code"
              value={selectedCoupon?.code}
              onChange={handleEditChange}
            />
            <input
              type="text"
              className="form-control"
              placeholder="Enter Discount"
              name="discount"
              value={selectedCoupon?.discount}
              onChange={handleEditChange}
            />
            <input
              type="text"
              className="form-control"
              placeholder="Minimum Purchase Price"
              name="minPrice"
              value={selectedCoupon?.minPrice}
              onChange={handleEditChange}
            />
            <input
              type="date"
              className="form-control"
              placeholder="Expiration Date"
              name="expirationDate"
              value={
                selectedCoupon?.expirationDate ? new Date(selectedCoupon.expirationDate).toISOString().split('T')[0] : ''}
              onChange={handleEditChange}
            />
            <select 
              className="w-100"
              name="isActive"
              value={selectedCoupon?.isActive}
              onChange={handleEditChange}
            >
              <option value={true}>Active</option>
              <option value={false}>Inactive</option>
            </select>
            <button
              className="add-to-cart-btn m-0"
              onClick={() => handleEditCoupon(selectedCoupon?._id)}
            >
              {loading ? "Adding.." : "Make Changes"}
            </button>
          </div>
        </Modal>
      )}
    </AdminLayout>
  );
};

export default AdminAddCoupon;
