import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "./Header.css";
import SideMenu from "./SideMenu";
import Backdrop from "./Backdrop";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../redux/features/userSlice";
import axios from "../../Axios";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Tippy from "@tippyjs/react";
import LogoutTippy from "./LogoutTippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import SearchIcon from "@mui/icons-material/Search";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import PersonIcon from "@mui/icons-material/Person";
import MenuIcon from "@mui/icons-material/Menu";
import SearchContainer from "../SearchContainer";
import CartSideMenu from "../CartSideMenu";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import IMAGES from "../../img/image";
import getUserData from "../../utils/userDataService.js";
import DiamondIcon from "@mui/icons-material/Diamond";
import Marquee from "react-fast-marquee";
import CloseIcon from '@mui/icons-material/Close';

const Header = () => {
  const { user } = useSelector((state) => state.user);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [sideMenu, setSideMenu] = useState(false);
  const [profileMenu, setProfileMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [cartMenu, setCartMenu] = useState(false);
  const [balance, setBalance] = useState("");

  useEffect(() => {
    getUserData(dispatch, setUser, setBalance);
  }, []);

  return (
    <>
      <header className="header">
        <div className="header-main">
          <div className="burger-icon" onClick={() => setSideMenu(!sideMenu)}>
            <MenuIcon className="icon" />
          </div>
          <SideMenu sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <Backdrop sideMenu={sideMenu} setSideMenu={setSideMenu} />
          <div className="logo" onClick={() => navigate("/")}>
            <DiamondIcon className="me-2 icon" />
            BUSAN
            <span>OFFICIAL</span>
          </div>
          <div className="action-btns">
            {user && (
              <div onClick={() => navigate("/wallet")} className="wallet-cont">
                <span className="me-2">
                  <AccountBalanceWalletIcon className="icon" />
                </span>
                <span>
                  <b>{balance}</b>
                </span>
              </div>
            )}
            <SearchIcon onClick={() => setSearch(!search)} className="icon" />
            <Tippy
              interactive
              theme="light"
              content={<LogoutTippy user={user && user} />}
            >
              <span className="menu-img-container d-flex">
                <PersonIcon
                  className="icon d-lg-block d-md-none d-none"
                  onClick={() => navigate("/login")}
                />
                {user && (
                  <KeyboardArrowDownIcon className="d-lg-block d-md-none d-none text-white" />
                )}
              </span>
            </Tippy>
          </div>
        </div>
      </header>
      <CartSideMenu cartMenu={cartMenu} setCartMenu={setCartMenu} />
      <SearchContainer search={search} setSearch={setSearch} />

      <div className="header-bottom">
        <CloseIcon className="close-icon" onClick={() => {document.getElementsByClassName("header-bottom")[0].classList.add("display-none")}} />
        <div className="text-center">
          Click Here to join our WhatsApp Channel to get updates and promotions{" "}
          <a
            className="mx-1"
            href="https://whatsapp.com/channel/0029Vaf2kfvDDmFab1IPIz0z"
            target="_blank"
          >
            Click Here
          </a>{" "}
        </div>
      </div>
    </>
  );
};

export default Header;
