import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../Axios";
import "./Games.css";

const Games = ({ title }) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  const renderCategorySection = (categoryName) => {
    const filteredProducts = products?.filter(
      (item) => item?.category === categoryName
    );
    if (!filteredProducts?.length) return null;
    return (
      <>
        <h1 className="hrline mb-4 mb-lg-5">{categoryName}</h1>
        <div className="game-product">
          {filteredProducts.map((item, index) => (
            <div
              key={index}
              className="game"
              onClick={() => navigate(`/product/${item?.name}`)}
            >
              <img
                src={`https://busanofficial.com/${item?.image}`}
                alt={item?.name}
              />
              <h5>{item?.name}</h5>
              {item?.hotTags && (
                <div className="hottag">
                  <span>{item?.hotTags}</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <div className="games-container">
      {renderCategorySection("Popular")}
      {renderCategorySection("Direct Topup")}
      {renderCategorySection("Coupon Code")}
      {renderCategorySection("Other")}
    </div>
  );
};

export default Games;
